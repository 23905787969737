
import { defineComponent, ref, watch, computed } from 'vue';
import router from '../router';
import KroggerMoveAndRotateCode from '@/components/KroggerMoveAndRotateCode.vue';
import KroggerQueueMovement from '@/components/KroggerQueueMovement.vue';
import KroggerGetList from '@/components/KroggerGetList.vue';
import KroggerSaveScore from '@/components/KroggerSaveScore.vue';
import KroggerDoRequest from '@/components/KroggerDoRequest.vue';

export default defineComponent({
  components: {
    KroggerMoveAndRotateCode,
    KroggerQueueMovement,
    KroggerGetList,
    KroggerSaveScore,
    KroggerDoRequest,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close-modal'],
  setup(props) {
    const closing = ref<boolean>(false);
    const showMovementCode = ref<boolean>(false);
    const showHighScoreCode = ref<boolean>(false);

    const scollPosition = ref<number>(0);

    const onClickModalClose = () => {
      router.push('/');
      return false;
    };

    const onClickToggleMovementCode = () => {
      showMovementCode.value = !showMovementCode.value;
    };

    const onClickToggleHighScoreCode = () => {
      showHighScoreCode.value = !showHighScoreCode.value;
    };

    const MovementCodeIcon = computed(() => {
      return !showMovementCode.value ? 'square-plus' : 'square-minus';
    });

    const HighScoreCodeIcon = computed(() => {
      return !showHighScoreCode.value ? 'square-plus' : 'square-minus';
    });

    watch(
      () => props.active,
      () => {
        scollPosition.value =
          document.documentElement.scrollTop || document.body.scrollTop;
      },
    );

    return {
      closing,
      showMovementCode,
      showHighScoreCode,
      MovementCodeIcon,
      HighScoreCodeIcon,
      onClickModalClose,
      onClickToggleMovementCode,
      onClickToggleHighScoreCode,
    };
  },
});
